import React from "react";
import "../css/Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <img
        src="/images/Logos/SmallLogo.png"
        alt="Logo"
        className="FooterLogoImage"
      />
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="footer-content1">
              <img
                src="/images/Logos/LargeLogo.png"
                alt="Logo"
                className="logo-image"
              />
              <ul className="footer-menu">
                <Link className="nav-link" to="/">
                  <li>Home</li>
                </Link>
                <Link className="nav-link" to="/portfolio">
                  <li>Portfolio</li>
                </Link>
                <Link className="nav-link" to="/services">
                  <li>Services</li>
                </Link>
                <Link className="nav-link" to="/industries">
                  <li>Industries</li>
                </Link>
                <Link className="nav-link" to="/about">
                  <li>About</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="col-6">
            <div className="footer-content2">
              <h2>Contact Us</h2>
              <p className="contact-info">
                Primary Phone: 778.238.9933
                <br />
                Secondary Phone: 778.995.2407
                <br />
                Email: info@alpha1services.com
                <br />
                Hours: 7:00am-6:00pm (M-F)
                <br />
                Weekends: Available by appointment
                <br />
                2228 Welcher Ave, Port Coquitlam BC
              </p>
              <div className="social-icons">
                <i className="social-icon fab fa-facebook-f">
                  <img
                    src="/images/SocialIcons/facebook.png"
                    alt="facebook"
                    className="logo-image"
                  />
                </i>
                <i className="social-icon fab fa-twitter">
                  <img
                    src="/images/SocialIcons/twitter.png"
                    alt="twitter"
                    className="logo-image"
                  />
                </i>
                <i className="social-icon fab fa-instagram">
                  <img
                    src="/images/SocialIcons/instagram.png"
                    alt="instagram"
                    className="logo-image"
                  />
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
