import "../css/ServicesWB.css"; // Import the CSS file

const ServicesWB = () => {
  return (
    <div className="ServicesWBContainer">
      <div className="ServicesWBContent">
        <p className="ServicesLargeSentence">
        <b>Precision in Motion</b>, Alpha 1 Industrial Services Delivers
        </p>
      </div>
    </div>
  );
};

export default ServicesWB;
