import "../../ServicesPage/css/ServicesWB.css"; // Import the CSS file

const IndustriesWB = () => {
  return (
    <div className="ServicesWBContainer">
      <div className="ServicesWBContent">
        <p className="ServicesLargeSentence">
        <b>Flawless Motion Precision</b>, Alpha 1 Industrial Services Delivers
        </p>
      </div>
    </div>
  );
};

export default IndustriesWB;
