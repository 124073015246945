import React from "react";
import axios from "axios";
import Select from "react-select";
import "../css/ContactGrid.css";

function ContactGrid() {
  const [formData, setFormData] = React.useState({
    companyName: "",
    email: "",
    phoneNumber: "",
    howDidYouHear: "",
    details: "",
  });
  const [message, setMessage] = React.useState(""); // State for confirmation message


  const options = [
    { value: "Google", label: "Google" },
    { value: "Facebook", label: "Facebook" },
    { value: "Referral", label: "Referral" },
    { value: "Instagram", label: "Instagram" },
    { value: "Sign", label: "Sign" },
    { value: "Other", label: "Other" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(""); // Reset message
    try {
      const response = await axios.post("https://alpha1industrial.com/sendmail", formData);

      if (response.status === 200) {
        setMessage('Quote request Sent, we will get back to you as soon as possible!');
      } else {
        setMessage('Our system is currently down, we are working to fix this! Please try again later');
      }
    } catch (error) {
      console.error("Error sending email", error);
      setMessage('Failed to send email. Please check your connection and try again.');
    }
  };


  return (
    <div className="ContactGrid">
      <div className="ContactInfoSection">
        <div className="ExpertMessage">
          <h1>
          <strong>Would you prefer to talk with an expert now?</strong>
          </h1>
        </div>
        <div className="ContactWithTeam">
          <br />
          <p style={{ fontSize: "40px", marginTop: "50px" }}>
            Speak with the team:
          </p>
        </div>
        <div className="ContactDetails">
          <div className="ContactRectangle">
            <div className="PhoneLogo">📞</div>
            <div
              className="PhoneNumber"
              style={{ fontSize: "35px", letterSpacing: "0.1em" }}
            >
              778-238-9933
            </div>
          </div>
          <br />
        </div>
        <div className="HoursText">
          <p style={{ fontSize: "20px", marginTop: "40px" }}>
            Hours: 7:00am-6:00pm (M-F)
          </p>
        </div>
      </div>

      <div className="ContactFormSection">
        <div className="FormTitle">
          <h1>REQUEST A QUOTE</h1>
          <p style={{ fontSize: "smaller" }}>LEAVE A MESSAGE BELOW</p>
        </div>
        <form className="FormInputs" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Company Name*"
            required
            value={formData.companyName}
            onChange={(e) =>
              setFormData({ ...formData, companyName: e.target.value })
            }
          />
          <input
            type="email"
            placeholder="Email Address*"
            required
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          <input
            type="tel"
            placeholder="Phone Number*"
            required
            value={formData.phoneNumber}
            onChange={(e) =>
              setFormData({ ...formData, phoneNumber: e.target.value })
            }
          />
          <Select
            className="react-select-container"
            placeholder="How did you hear about us?"
            options={options}
            value={options.find(
              (option) => option.value === formData.howDidYouHear
            )}
            onChange={(selectedOption) =>
              setFormData({
                ...formData,
                howDidYouHear: selectedOption ? selectedOption.value : "",
              })
            }
          />
          <textarea
            placeholder="Tell us some details about your job / inquiry"
            rows="5"
            required
            value={formData.details}
            onChange={(e) =>
              setFormData({ ...formData, details: e.target.value })
            }
          ></textarea>

          <button type="submit" className="SubmitButton">
            SUBMIT
          </button>
          <br />
          <div className="ConfirmationMessage">
            {message && <p>{message}</p>} {/* Display confirmation message here */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactGrid;
