import React from "react";
import Footer from "../../Footer/js/Footer";
import Header from "../../Header/js/Header";
import ServicesHero from "./ServicesHero";
import ServicesWB from "./ServicesWB";
import ServicesDropDowns from "./ServicesDropDowns";
import ServicesDropDowns1 from "./ServicesDropDowns1";


function Services() {
  return (
    <div className="Services">
      <Header />
      <ServicesHero />
      <ServicesWB />
      <ServicesDropDowns />
      <ServicesDropDowns1 />
      <Footer />
    </div>
  );
}

export default Services;
