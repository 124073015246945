import "../css/AUCoreValues.css"; // Import the CSS file

const AUCoreValues = () => {
  return (
    <div className="AUCoreValuesContainer">
      <div className="CoreValuesTitle">
        <div className="CoreValuesTitleGrid">
          <img
            src="/images/Logos/SmallLogo.png"
            alt="Logo"
            className="CoreValuesLogoImage"
          />
          <h1 className="AUCoreValuesTitle">
            <span style={{ color: "Red" }}>Our</span> Core Values
          </h1>
        </div>
      </div>

      <div className="theValuesGrid1">
        <div className="Excellence">
          <img
            src="/images/AUImages/Excellence.png"
            alt="Excellence"
            className="CoreValImg"
          />
          <h2>
            <strong>Efficiency</strong>
          </h2>
          <p>
            We pride ourselves on eliminating downtime and optimizing speed in
            every move.
          </p>
        </div>
        <div className="Integrity">
          <img
            src="/images/AUImages/Integrity.png"
            alt="Integrity"
            className="CoreValImg"
          />
          <h2>
            <strong>Integrity</strong>
          </h2>
          <p>
            Operating with unwavering honesty, transparency, and ethical
            practices to foster trust and credibility.
          </p>
        </div>
        <div className="Reliability">
          <img
            src="/images/AUImages/Reliability.png"
            alt="Reliability"
            className="CoreValImg"
          />
          <h2>
            <strong>Innovation</strong>
          </h2>
          <p>
            Committed to pioneering advancements and embracing new ideas, we
            continuously seek innovative solutions that improve our services and
            set industry standards.
          </p>
        </div>
      </div>
      <div className="theValuesGrid2">
        <div className="Collaboration">
          <img
            src="/images/AUImages/Collaboration.png"
            alt="Collaboration"
            className="CoreValImg"
          />
          <h2>
            <strong>Communication</strong>
          </h2>
          <p>
            With an open line of communication, we collaborate closely with
            clients to achieve optimal outcomes.
          </p>
        </div>
        <div className="Safety">
          <img
            src="/images/AUImages/Safety.png"
            alt="Safety"
            className="CoreValImg"
          />
          <h2>
            <strong>Safety</strong>
          </h2>
          <p>
            Prioritizing the well-being of all stakeholders through strict
            adherence to safety protocols and industry standards.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AUCoreValues;
