import React, { useState } from "react";
import "../css/Gallery.css"; // Import the CSS file

import Menu from "./Menu";

const GalleryReact = () => {
    const [items, setItems] = useState(Menu);
    const [activeCategory, setActiveCategory] = useState("all");
    const [expandedImage, setExpandedImage] = useState(null);
  
    const filterItem = (categItem) => {
      const updateItems = Menu.filter((curElem) => {
        return curElem.category === categItem;
      });
  
      setItems(updateItems);
      setActiveCategory(categItem);
    };
  
    const showAllItems = () => {
      setItems(Menu);
      setActiveCategory("all");
    };
  
    const openModal = (image) => {
      setExpandedImage(image);
    };
  
    const closeModal = () => {
      setExpandedImage(null);
    };
  
  return (
    <div className="GalleryContainer">
      <br />
      <div className="GContainer">
        <ul className="GNav">
        <li className="GNav-item">
            <button
              className={activeCategory === 'all' ? "GNav-link Active" : "GNav-link"}
              onClick={showAllItems}
            >
              All
            </button>
          </li>
          <li className="GNav-item">
            <button
              className={activeCategory === 'Machine-Moving' ? "GNav-link Active" : "GNav-link"}
              onClick={() => filterItem("Machine-Moving")}
            >
              Machine Moving
            </button>
          </li>
          <li className="GNav-item">
            <button
              className={activeCategory === 'Warehousing' ? "GNav-link Active" : "GNav-link"}
              onClick={() => filterItem("Warehousing")}
            >
              Warehousing
            </button>
          </li>
          <li className="GNav-item">
            <button
              className={activeCategory === 'Truck-Services' ? "GNav-link Active" : "GNav-link"}
              onClick={() => filterItem("Truck-Services")}
            >
              Truck / Crane Services
            </button>
          </li>
          <li className="GNav-item">
            <button
              className={activeCategory === 'Millwright-Services' ? "GNav-link Active" : "GNav-link"}
              onClick={() => filterItem("Millwright-Services")}
            >
              Millwright Services
            </button>
          </li>
        </ul>
      </div>

      <div className="GContainer-fluid Gmt-4" id="GItemContainer">
        <div className="GRow">
          {items.map((elem) => {
            const { id, name, image, description } = elem;

            return (
                <div className="GCol-sm-4" key={id}>
                <div className="GCard">
                  <div
                    className="GCard-img"
                    onClick={() => openModal(image)}
                  >
                    <img src={image} alt={name} />
                  </div>
                  <div className="GCard-body">
                    <h5 className="GCard-title">{name}</h5>
                    <p className="GCard-text">{description}</p>
                    <div className="Gd-flex">
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* Modal for expanded image */}
      {expandedImage && (
        <div className="ImageModal"
        onClick={closeModal}>
          <span className="CloseIcon" onClick={closeModal}>
            &times;
          </span>
          <img src={expandedImage} alt="Expanded" />
        </div>
      )}
    </div>
  );
};

export default GalleryReact;
