import React, { useEffect } from "react";
import "../css/IconBox.css"; // Import the CSS file

const IconBox = () => {
  useEffect(() => {
    // Load the lord-icon script dynamically
    const script = document.createElement("script");
    script.src = "https://cdn.lordicon.com/bhenfmcm.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup the observer and remove the dynamically loaded script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="IconBoxContainer">
      <div className="iconBoxContent">
        <div className="calendarGroup">
          <lord-icon
            src="https://cdn.lordicon.com/zzcjjxew.json"
            trigger="hover"
            colors="primary:#121331,secondary:#c71f16"
            state="hover-jump-spin"
            style={{ width: "250px", height: "250px" }}
          />
          <div className="IconText">
            <span style={{ color: "red" }}>25</span> YEARS OF COLLECTIVE EXPERIENCE
          </div>
        </div>
        <div className="projectGroup">
          <script src="https://cdn.lordicon.com/bhenfmcm.js"></script>
          <lord-icon
            src="https://cdn.lordicon.com/sbiheqdr.json"
            trigger="hover"
            colors="primary:#121331,secondary:#c71f16"
            style={{ width: "250px", height: "250px" }}
          />
          <div className="IconText">
            OVER <span style={{ color: "red" }}>250</span> PROJECTS COMPLETED
          </div>
        </div>
        <div className="mapGroup">
          <script src="https://cdn.lordicon.com/bhenfmcm.js"></script>
          <lord-icon
            src="https://cdn.lordicon.com/kbtmbyzy.json"
            trigger="hover"
            colors="primary:#121331,secondary:#c71f16"
            style={{ width: "250px", height: "250px" }}
          />
          <div className="IconText">
            WORKED IN OVER <span style={{ color: "red" }}>40</span> CITIES IN BC
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconBox;
