const Menu = [
  // {
  //   id: 1,
  //   image: "/images/Gallery/Warehousing/SafeMoving.png",
  //   name: "Warehousing",
  //   category: "Warehousing"
  // },
  {
    id: 2,
    image: "/images/Gallery/TruckServices/TruckServices.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  // {
  //   id: 3,
  //   image: "/images/Gallery/MillwrightServices/MillwrightServices.png",
  //   name: "Millwright Services",
  //   category: "Millwright-Services"
  // },
  {
    id: 6,
    image: "/images/Gallery/MachineMoving/MachineMoving2.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 7,
    image: "/images/Gallery/MachineMoving/MachineMoving3.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 8,
    image: "/images/Gallery/MachineMoving/MachineMoving4.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 9,
    image: "/images/Gallery/MachineMoving/MachineMoving5.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 10,
    image: "/images/Gallery/MachineMoving/MachineMoving6.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 11,
    image: "/images/Gallery/MachineMoving/MachineMoving7.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 12,
    image: "/images/Gallery/MachineMoving/MachineMoving8.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 13,
    image: "/images/Gallery/MachineMoving/MachineMoving9.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 14,
    image: "/images/Gallery/MachineMoving/MachineMoving10.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 15,
    image: "/images/Gallery/MachineMoving/MachineMoving11.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 16,
    image: "/images/Gallery/MachineMoving/MachineMoving12.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 17,
    image: "/images/Gallery/MachineMoving/MachineMoving13.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 18,
    image: "/images/Gallery/MachineMoving/MachineMoving14.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 19,
    image: "/images/Gallery/MachineMoving/MachineMoving15.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 20,
    image: "/images/Gallery/MachineMoving/MachineMoving16.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 21,
    image: "/images/Gallery/MachineMoving/MachineMoving17.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 22,
    image: "/images/Gallery/MachineMoving/MachineMoving18.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 23,
    image: "/images/Gallery/MachineMoving/MachineMoving19.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 24,
    image: "/images/Gallery/MachineMoving/MachineMoving20.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 25,
    image: "/images/Gallery/MachineMoving/MachineMoving21.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 26,
    image: "/images/Gallery/MachineMoving/MachineMoving22.jpg",
    name: "Machine Moving",
    category: "Machine-Moving"
  },
  {
    id: 27,
    image: "/images/Gallery/TruckServices/TruckServices2.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 28,
    image: "/images/Gallery/TruckServices/TruckServices3.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 29,
    image: "/images/Gallery/TruckServices/TruckServices4.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 30,
    image: "/images/Gallery/TruckServices/TruckServices5.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 31,
    image: "/images/Gallery/TruckServices/TruckServices6.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 32,
    image: "/images/Gallery/TruckServices/TruckServices7.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 33,
    image: "/images/Gallery/TruckServices/TruckServices8.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 34,
    image: "/images/Gallery/TruckServices/TruckServices9.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 35,
    image: "/images/Gallery/TruckServices/TruckServices10.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 36,
    image: "/images/Gallery/TruckServices/TruckServices11.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 37,
    image: "/images/Gallery/TruckServices/TruckServices12.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 38,
    image: "/images/Gallery/TruckServices/TruckServices13.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 39,
    image: "/images/Gallery/TruckServices/TruckServices14.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 40,
    image: "/images/Gallery/TruckServices/TruckServices15.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 41,
    image: "/images/Gallery/TruckServices/TruckServices16.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 42,
    image: "/images/Gallery/TruckServices/TruckServices17.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
  {
    id: 43,
    image: "/images/Gallery/TruckServices/TruckServices18.jpg",
    name: "Truck / Crane Services",
    category: "Truck-Services"
  },
];

export default Menu;
