import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import RequestQuoteButton from "../../RequestButton/js/RequestQuoteButton";
import "../css/Header.css"; // Import the CSS file

const Header = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false); // State for off-canvas

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 992) {
        setShowOffcanvas(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  return (
    <header className="headerMain">
      <div className="logo">
        <Link className="nav-link" to="/">
          <img
            src="/images/Logos/SmallLogo.png"
            alt="Logo"
            className="HeaderLogoImage"
          />
        </Link>
      </div>
      <Navbar expand="lg">
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleOffcanvas}
        />
        <nav className="navbar-collapse" id="basic-navbar-nav">
          <ul className="nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                HOME
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/portfolio">
                PORTFOLIO
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services">
                SERVICES
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/industries">
                INDUSTRIES
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                ABOUT
              </Link>
            </li>
          </ul>
        </nav>

        <Offcanvas
          show={showOffcanvas}
          onHide={toggleOffcanvas}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>ALPHA 1</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav.Link href="/">HOME</Nav.Link>
            <Nav.Link href="/portfolio">PORTFOLIO</Nav.Link>
            <Nav.Link href="/services">SERVICES</Nav.Link>
            <Nav.Link href="/industries">INDUSTRIES</Nav.Link>
            <Nav.Link href="/about">ABOUT</Nav.Link>
            <br />
            <RequestQuoteButton />
          </Offcanvas.Body>
        </Offcanvas>
      </Navbar>
      <div className="RequestButtonMobile">
        <RequestQuoteButton />
      </div>
    </header>
  );
};

export default Header;
