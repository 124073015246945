import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../ServicesPage/css/ServicesDropDowns.css";
import RequestQuoteButton from "../../RequestButton/js/RequestQuoteButton";

const IndustriesDropDowns = () => {
  const [activeBox, setActiveBox] = useState(null);

  const handleBoxClick = (boxIndex) => {
    setActiveBox(boxIndex === activeBox ? null : boxIndex);
  };

  return (
    <div className="ServicesDropDownsContainer">
      <div className="BoxContainer">
        <div
          className={`Box Box1 ${activeBox === 0 ? "active" : ""}`}
          onClick={() => handleBoxClick(0)}
          style={{
            backgroundImage: `url("/images/IndustriesImages/MachineShopBG.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="BoxTitle">MACHINE SHOPS</div>
          <div className={`Triangle1 ${activeBox === 0 ? "active" : ""}`}></div>
        </div>
        <div
          className={`Dropdown DropdownBox1 ${activeBox === 0 ? "active" : ""}`}
        >
          <div className="LeftHalf">
            <img
              src="/images/IndustriesImages/MachineShop.jpg"
              alt="Machinery Moving"
              className="MMimg"
            />
          </div>
          <div className="RightHalf">
            <div className="Title">MACHINE SHOPS</div>
            <div className="Paragraph">
              Alpha 1 Industrial Services offers comprehensive turnkey solutions
              for industrial equipment and machine shop moving across the Lower
              Mainland, including Surrey, Richmond, Vancouver, and Burnaby.
            </div>
            <div className="Buttons">
              <Link className="nav-link" to="/portfolio">
                <button className="ViewOurWorkButton">VIEW OUR WORK</button>
              </Link>
              <RequestQuoteButton />
            </div>
          </div>
        </div>
      </div>
      <div className="BoxContainer">
        <div
          className={`Box Box2 ${activeBox === 1 ? "active" : ""}`}
          onClick={() => handleBoxClick(1)}
          style={{
            backgroundImage: `url("/images/IndustriesImages/CommercialBG.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="BoxTitle">COMMERCIAL</div>
          <div className={`Triangle1 ${activeBox === 1 ? "active" : ""}`}></div>
        </div>
        <div
          className={`Dropdown DropdownBox2 ${activeBox === 1 ? "active" : ""}`}
        >
          <div className="LeftHalf">
            <img
              src="/images/IndustriesImages/Commercial.jpeg"
              alt="Commercial"
              className="SMimg"
            />
          </div>
          <div className="RightHalf">
            <div className="Title">COMMERCIAL</div>
            <div className="Paragraph">
              <p>
                Alpha 1 Industrial Services offers specialized installation and
                relocation services for commercial equipment across the Lower
                Mainland, including Surrey, Richmond, Vancouver, and Burnaby. We
                cater to a variety of sectors such as restaurants, hospitals,
                schools and universities, food manufacturing facilities,
                bakeries, commercial laundries, and warehouse inventory moving
                ensuring efficient and secure setup and moving of machinery and
                equipment critical to your operations.
              </p>
            </div>
            <div className="Buttons">
              <Link className="nav-link" to="/portfolio">
                <button className="ViewOurWorkButton">VIEW OUR WORK</button>
              </Link>
              <RequestQuoteButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustriesDropDowns;
