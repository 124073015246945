import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/Accordion.css"; // Import the CSS file

const Accordion = () => {
  const [openPanel, setOpenPanel] = useState(null);

  const handleLabelClick = (event) => {
    event.preventDefault();
    const panelId = event.currentTarget.getAttribute("for");
    const behindAccordionContent = document.querySelector(
      ".behindAccordionContent"
    );

    if (openPanel === panelId) {
      behindAccordionContent.style.zIndex = "1";
      setOpenPanel(null);
    } else {
      behindAccordionContent.style.zIndex = "0"; // Or any value you want
      setOpenPanel(panelId);
    }
  };

  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (sectionNumber) => {
    // Get all accordion buttons
    const accordionButtons = document.querySelectorAll(".accordionMobile1");

    // Remove the active class from all buttons
    accordionButtons.forEach((button) => {
      button.classList.remove("active");
    });

    if (openSection === sectionNumber) {
      setOpenSection(null); // Close the section if it's already open
    } else {
      // Otherwise, open this section and add the active class to the clicked button
      accordionButtons[sectionNumber - 1].classList.add("active");
      setOpenSection(sectionNumber);
    }
  };

  return (
    <div className="accordionContainer">
      <div className="accordionDesktop">
        <div className="behindAccordionContent">
          <div className="BackDropContent">
            <p className="BackDropLargeSentence">
              Expanding industrial moving services in Lower Mainland.
            </p>

            <Link className="BackDropSmallSentence" to="/services">
              LEARN MORE
            </Link>
          </div>
          <div className="backdropTitle">
            <h1 className="move">MOVING</h1>
            <h1 className="theImpossible">THE IMPOSSIBLE</h1>
          </div>
        </div>
        <ul
          style={{
            background: 'url("/images/AccordionImages/Red.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          <li>
            <input
              id="rad1"
              type="radio"
              name="rad"
              checked={openPanel === "rad1"}
              onChange={() => setOpenPanel("rad1")}
            />
            <label htmlFor="rad1" onClick={handleLabelClick}>
              <div className="title" id="rad1">
                MILLWRIGHT SERVICES
              </div>
            </label>
            <div
              className="accslide"
              style={{
                background:
                  'url("/images/AccordionImages/MillwrightServicesBG.png")',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="content1">
                <div className="panel1-image-wrapper">
                  <img
                    src="/images/AccordionImages/MillwrightServices.jpg"
                    alt="MillwrightServices"
                    className="panel1-image"
                  />
                </div>
                <h1 id="title">MILLWRIGHT SERVICES</h1>
                <h1 id="info1">
                  Seamless transitions with skilled millwright services for
                  efficient machinery moving expertise.
                </h1>
                <Link to="/services">
                  <p id="learn">LEARN MORE</p>
                </Link>
              </div>
            </div>
          </li>
          <li>
            <input
              id="rad2"
              type="radio"
              name="rad"
              checked={openPanel === "rad2"}
              onChange={() => setOpenPanel("rad2")}
            />
            <label htmlFor="rad2" onClick={handleLabelClick}>
              <div className="title" id="rad2">
                TRUCK / CRANE SERVICES
              </div>
            </label>
            <div
              className="accslide"
              style={{
                background:
                  'url("/images/AccordionImages/TruckServicesBG.png")',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="content2">
                <div className="panel2-image-wrapper">
                  <img
                    src="/images/AccordionImages/TruckServices.jpg"
                    alt="TruckServices"
                    className="panel2-image"
                  />
                </div>
                <h1 id="title">TRUCK SERVICES</h1>
                <h1 id="info2">
                  Experience unparalleled crane services: <b>power</b>,{" "}
                  <b>precision</b>, and
                  <b>safety</b> combined.
                </h1>
                <Link to="/services">
                  <p id="learn">LEARN MORE</p>
                </Link>
              </div>
            </div>
          </li>
          <li>
            <input
              id="rad3"
              type="radio"
              name="rad"
              checked={openPanel === "rad3"}
              onChange={() => setOpenPanel("rad3")}
            />
            <label htmlFor="rad3" onClick={handleLabelClick}>
              <div className="title" id="rad3">
                WAREHOUSING
              </div>
            </label>
            <div
              className="accslide"
              style={{
                background: 'url("/images/AccordionImages/WarehousingBG.png")',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="content3">
                <div className="panel3-image-wrapper">
                  <img
                    src="/images/AccordionImages/Warehousing.png"
                    alt="Warehousing"
                    className="panel3-image"
                  />
                </div>
                <h1 id="title">WAREHOUSING</h1>
                <h1 id="info3">
                  Coming soon: Secure and hassle-free warehousing solutions for
                  all your storage needs.
                </h1>
                <Link to="/services">
                  <p id="learn">LEARN MORE</p>
                </Link>
              </div>
            </div>
          </li>
          <li>
            <input
              id="rad4"
              type="radio"
              name="rad"
              checked={openPanel === "rad4"}
              onChange={() => setOpenPanel("rad4")}
            />
            <label htmlFor="rad4" onClick={handleLabelClick}>
              <div className="title" id="rad4">
                MACHINE MOVING
              </div>
            </label>
            <div
              className="accslide"
              style={{
                background:
                  'url("/images/AccordionImages/MachineMovingBG.png")',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="content4">
                <div className="panel4-image-wrapper">
                  <img
                    src="/images/AccordionImages/MachineMoving.jpg"
                    alt="MachineMoving"
                    className="panel4-image"
                  />
                </div>
                <h1 id="title">MACHINE MOVING</h1>
                <h1 id="info4">
                  Efficient machinery moving expertise for seamless transitions.
                </h1>
                <Link to="/services">
                  <p id="learn">LEARN MORE</p>
                </Link>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div
        className="accordionMobileIntro"
        style={{
          background: 'url("/images/AccordionImages/Red.png")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="behindAccordionContent">
          <div className="BackDropContent">
            <p className="BackDropLargeSentence">
              Expanding industrial moving services in Lower Mainland.
            </p>
            <Link className="BackDropSmallSentence" to="/services">
              LEARN MORE
            </Link>
          </div>
        </div>
      </div>

      <div className="accordionMobile">
        <button className="accordionMobile1" onClick={() => toggleSection(1)}>
          MACHINE MOVING
        </button>
        <div
          className="panel"
          style={{
            minHeight: openSection === 1 ? "60vh" : "0",
            background: 'url("/images/AccordionImages/MachineMovingBG.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="panelMobile">
            <img
              src="/images/AccordionImages/MachineMoving.jpg"
              alt="MachineMoving"
              className="panel-image-mobile"
            />
          </div>
          <div className="infoMobile">
            <p>
              Efficient machinery moving expertise for seamless transitions.
            </p>
            <Link to="/services">
              <p id="learnMobile">LEARN MORE</p>
            </Link>
          </div>
        </div>

        <button className="accordionMobile1" onClick={() => toggleSection(2)}>
          WAREHOUSING
        </button>
        <div
          className="panel"
          style={{
            minHeight: openSection === 2 ? "70vh" : "0",
            background: 'url("/images/AccordionImages/WarehousingBG.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="panelMobile">
            <img
              src="/images/AccordionImages/Warehousing.png"
              alt="Warehousing"
              className="panel-image-mobile"
            />
          </div>
          <div className="infoMobile"></div>
          <div className="infoMobile">
            <p>
              Coming soon: Secure and hassle-free warehousing solutions for all
              your storage needs.
            </p>
            <Link to="/services">
              <p id="learnMobile">LEARN MORE</p>
            </Link>
          </div>
        </div>

        <button className="accordionMobile1" onClick={() => toggleSection(3)}>
          TRUCK / CRANE SERVICES
        </button>
        <div
          className="panel"
          style={{
            minHeight: openSection === 3 ? "60vh" : "0",
            background: 'url("/images/AccordionImages/TruckServicesBG.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="panelMobile">
            <img
              src="/images/AccordionImages/TruckServices.jpg"
              alt="TruckServices"
              className="panel-image-mobile"
            />
          </div>
          <div className="infoMobile">
            <p>
              Experience unparalleled crane services: power, precision, and
              safety combined.
            </p>
            <Link to="/services">
              <p id="learnMobile">LEARN MORE</p>
            </Link>
          </div>
        </div>

        <button className="accordionMobile1" onClick={() => toggleSection(4)}>
          MILLWRIGHT SERVICES
        </button>
        <div
          className="panel"
          style={{
            minHeight: openSection === 4 ? "60vh" : "0",
            background:
              'url("/images/AccordionImages/MillwrightServicesBG.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="panelMobile">
            <img
              src="/images/AccordionImages/MillwrightServices.jpg"
              alt="MillWrightServices"
              className="panel-image-mobile"
            />
          </div>
          <div className="infoMobile">
            <p>
              Seamless transitions with skilled millwright services for
              efficient machinery moving expertise.
            </p>
            <Link to="/services">
              <p id="learnMobile">LEARN MORE</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
