import "../css/AUIntro.css"; // Import the CSS file

const AUIntro = () => {
  return (
    <div className="AUIntroContainer">
      <div className="AUIntro">
        <img
          src="/images/Logos/LogoWithOutA.png"
          alt="Logo"
          className="LogoWithOutA"
        />
        <p>
          Established as a family-run business, we, a dedicated father and sons
          team, have proudly carried our legacy from the dynamic landscapes of
          South Africa to the bustling industrial heart of British Columbia,
          Canada. For over four generations, our company has been synonymous
          with unparalleled expertise in machine moving and relocation services.
        </p>
        <p>
          Embracing our rich heritage and the lessons passed down through
          generations, our ethos have not changed. It has always been centered
          around adaptability, precision and speed.
        </p>
      </div>
    </div>
  );
};

export default AUIntro;
