import "../css/RequestQuoteButton.css"; // Import the CSS file
import { Link } from "react-router-dom";

const RequestQuoteButton = () => {
  return (
    <Link className="nav-link" to="/contact">
    <button className="quote-button">REQUEST A QUOTE</button>
    </Link>
  );
};

export default RequestQuoteButton;