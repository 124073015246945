import React from "react";
import Header from "../../Header/js/Header";
import IndustriesHero from "./IndustriesHero";
import IndustriesWB from "./IndustriesWB";
import IndustriesDropDowns from "./IndustriesDropDowns";
import IndustriesDropDowns1 from "./IndustriesDropDowns1";
import Footer from "../../Footer/js/Footer";

function Industries() {
  return (
    <div className="Industries">
      <Header />
      <IndustriesHero />
      <IndustriesWB />
      <IndustriesDropDowns />
      <IndustriesDropDowns1 />
      <Footer />
      
    </div>
  );
}

export default Industries;
