
import "../css/FooterRequest.css"; // Import the CSS file
import { Link } from "react-router-dom";

const FooterRequest = () => {

  return (
    <div className="FRContainer">
      <div className="Triangle">
      </div>
      <div className="FRContent">
        <h2 className="FRTitle">Share <span style={{ color: "black" }}>your</span> project details with us.</h2>
        <Link className="nav-link" to="/contact">
        <button className="FRSquareButton">REQUEST A QUOTE</button>
        </Link>

      </div>
    </div>
  );
};

export default FooterRequest;
