import React from "react";
import "../css/PortfolioHero.css";

function PortfolioHero() {
  return (
    <div className="PortfolioHeroContainer">
      <div className="PortfolioContainer">
        <h2 className="PortfolioTitle">
          View <span style={{ color: "Red" }}>Our</span> Work
        </h2>
        <p className="PortfolioText">
          Portfolio
        </p>
      </div>
    </div>
  );
}

export default PortfolioHero;
