import React from "react";
import "../css/AUHero.css";

function AUHero() {
  return (
    <div className="AUHeroContainer">
      <div className="AUTitleContainer">
        <h2 className="AUHeroTitle">
          Who <span style={{ color: "Red" }}>We</span> Are
        </h2>
        <p className="AUHeroText">
          About <span style={{ color: "Red" }}>Us</span>
        </p>
      </div>
    </div>
  );
}

export default AUHero;
