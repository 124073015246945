import "../css/AUWritingBox2.css"; // Import the CSS file
import RequestQuoteButton from "../../RequestButton/js/RequestQuoteButton";

const AUWritingBox2 = () => {
  return (
    <div className="AUWritingBox2Container">
        <p>
          We are more than just a service provider, we are a partner in your
          industrial endeavors, and our family's commitment to excellence is the
          cornerstone of our business.
        </p>

        <p>
          <b>Welcome to our legacy.</b>
        </p>
      <RequestQuoteButton />
    </div>
  );
};

export default AUWritingBox2;
