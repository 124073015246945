// useScrollFadeIn.js
import { useState, useEffect, useRef } from 'react';
import "../AnimationCSS/ScrollFadeInOutLeft.css";

const ScrollFadeInOutLeft = () => {
  const [isVisible, setIsVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const currentRef = domRef.current;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setIsVisible(entry.isIntersecting));
    });

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return [domRef, isVisible ? 'fadeInLeft' : 'fadeOutLeft'];
};

export default ScrollFadeInOutLeft;
