import "../css/HomeAboutUs.css"; // Import the CSS file
import ScrollFadeInOutLeft from "../../Animations/AnimationJS/ScrollFadeInOutLeft";
import ScrollFadeInOutRight from "../../Animations/AnimationJS/ScrollFadeInOutRight";
import { Link } from "react-router-dom";

const HomeAboutUs = () => {
  const [ref, fadeLeft] = ScrollFadeInOutLeft();
  const [ref2, fadeRight] = ScrollFadeInOutRight();
  const [ref3, fadeLeft3] = ScrollFadeInOutLeft();

  return (
    <div className="homeAboutUsContainer">
      <div className="homeAUtitle">
        <div className="homeAUtitleGrid">
          <h1 className="largeSentenceAU">
            Alpha 1 Industrial Services is your trusted partner.
            <Link to="/about">
            <p id="learnAU">Learn More About Us</p>
            </Link>
          </h1>

          <img
            src="/images/Logos/SmallLogo.png"
            alt="Logo"
            className="AULogoImage"
          />
        </div>
      </div>
      <div className="homeAUContentGrid">
        <div className="centerBar"></div>

        <div ref={ref} className={`AUimg1 ${fadeLeft}`}>
          <img
            src="/images/HomeAUImages/img1.jpg"
            alt="HomeAUImages"
            className="AUimgLeft"
          />
        </div>
        <div className="AUtext1">
          <h1 className="AUtext">
            Specializing in swift, efficient machine relocation, 
            we pride ourselves on eliminating downtime and optimizing speed in every move.
          </h1>
        </div>

        <div ref={ref2} className={`AUimg2 ${fadeRight}`}>
          <img
            src="/images/HomeAUImages/img3.jpg"
            alt="HomeAUImages"
            className="AUimgRight"
          />
        </div>
        <div className="AUtext2">
          <h1 className="AUtext">
          Building on four generations of expertise, 
          our family-run machine moving company combines vast 
          experience with a commitment to efficiency, 
          ensuring speedy and seamless relocations every time.
          </h1>
        </div>

        <div ref={ref3} className={`AUimg3 ${fadeLeft3}`}>
          <img
            src="/images/HomeAUImages/img2.jpg"
            alt="HomeAUImages"
            className="AUimgLeft"
          />
        </div>
        <div className="AUtext3">
          <h1 className="AUtext">
          Our company excels in adapting to diverse
          situations and problem-solving, ensuring efficient 
          and tailored solutions for every unique relocation challenge.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default HomeAboutUs;
