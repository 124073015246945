import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/ServicesDropDowns.css";
import RequestQuoteButton from "../../RequestButton/js/RequestQuoteButton";

const ServicesDropDowns = () => {
  const [activeBox, setActiveBox] = useState(null);

  const handleBoxClick = (boxIndex) => {
    setActiveBox(boxIndex === activeBox ? null : boxIndex);
  };

  return (
    <div className="ServicesDropDownsContainer">
      <div className="BoxContainer">
        <div
          className={`Box Box1 ${activeBox === 0 ? "active" : ""}`}
          onClick={() => handleBoxClick(0)}
        >
          <div className="BoxTitle">MACHINERY MOVING</div>
          <div className={`Triangle1 ${activeBox === 0 ? "active" : ""}`}></div>
        </div>
        <div
          className={`Dropdown DropdownBox1 ${activeBox === 0 ? "active" : ""}`}
        >
          <div className="LeftHalf">
            <img
              src="/images/ServicesImages/MachineMoving.jpg"
              alt="Machinery Moving"
              className="MMimg"
            />
          </div>
          <div className="RightHalf">
            <div className="Title">MACHINERY MOVING</div>
            <div className="Paragraph">
              At Alpha 1 Industrial Services, machinery moving is the
              cornerstone of our comprehensive range of industrial relocation
              services. With an expert team specializing in the precise handling
              of various machinery, we bring extensive experience in seamlessly
              moving, installing, and relocating both new and used equipment
              across manufacturing plants in the Lower Mainland.
              <br />
              <br />
              We offer moving services for variety of machinery and specialized stair moving, including: CNC, HVAC Units, Hot Water Tanks, Boilers, 
              Water Jet, Laser, Press Brake, Shear, Milling Machine, Lathe, Air
              Handling Units and more.
            </div>
            <div className="Buttons">
              <Link className="nav-link" to="/portfolio">
                <button className="ViewOurWorkButton">VIEW OUR WORK</button>
              </Link>
              <RequestQuoteButton />
            </div>
          </div>
        </div>
      </div>
      <div className="BoxContainer">
        <div
          className={`Box Box2 ${activeBox === 1 ? "active" : ""}`}
          onClick={() => handleBoxClick(1)}
        >
          <div className="BoxTitle">WAREHOUSING</div>
          <div className={`Triangle1 ${activeBox === 1 ? "active" : ""}`}></div>
        </div>
        <div
          className={`Dropdown DropdownBox2 ${activeBox === 1 ? "active" : ""}`}
        >
          <div className="LeftHalf">
            <img
              src="/images/ServicesImages/Warehousing.png"
              alt="Warehousing"
              className="SMimg"
            />
          </div>
          <div className="RightHalf">
            <div className="Title">WAREHOUSING</div>
            <div className="Paragraph">
              <p>
                Our forthcoming warehousing solutions will revolutionize your
                storage and logistics needs. With a focus on safety, efficiency,
                and convenience, our state-of-the-art facilities will provide
                secure and hassle-free storage options for your business. Stay
                tuned for more information on how we can streamline your
                inventory management and distribution processes.
              </p>
            </div>
            <div className="Buttons">
              <Link className="nav-link" to="/portfolio">
                <button className="ViewOurWorkButton">VIEW OUR WORK</button>
              </Link>
              <RequestQuoteButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesDropDowns;
