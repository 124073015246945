import "../css/AUWritingBox1.css"; // Import the CSS file

const AUWritingBox1 = () => {
  return (
    <div className="AUWritingBox1Container">
      <p>
        At Alpha 1 Industrial Services, we are a trusted leader in providing
        comprehensive rigging, machinery moving, and industrial relocation
        solutions. With unwavering commitment and a customer-centric approach,
        we strive to exceed expectations in every project we undertake.
      </p>
      <p>
        Drawing on over 25 years of collective industry experience, our expert
        team possesses the knowledge and expertise to handle complex challenges
        and deliver seamless results.
      </p>
    </div>
  );
};

export default AUWritingBox1;
