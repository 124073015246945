import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/ServicesDropDowns.css";
import RequestQuoteButton from "../../RequestButton/js/RequestQuoteButton";

const ServicesDropDowns = () => {
  const [activeBox, setActiveBox] = useState(null);

  const handleBoxClick = (boxIndex) => {
    setActiveBox(boxIndex === activeBox ? null : boxIndex);
  };

  return (
    <div className="ServicesDropDownsContainer">
      <div className="BoxContainer">
        <div
          className={`Box Box3 ${activeBox === 0 ? "active" : ""}`}
          onClick={() => handleBoxClick(0)}
        >
          <div className="BoxTitle">TRUCK SERVICES</div>
          <div className={`Triangle1 ${activeBox === 0 ? "active" : ""}`}></div>
        </div>
        <div
          className={`Dropdown DropdownBox3 ${activeBox === 0 ? "active" : ""}`}
        >
          <div className="LeftHalf">
            <img
              src="/images/ServicesImages/TruckServicesOG.jpg"
              alt="Machinery Moving"
              className="MMimg"
            />
          </div>
          <div className="RightHalf">
            <div className="Title">TRUCK / CRANE SERVICES</div>
            <div className="Paragraph">
              At Alpha 1 Industrial Services, our truck services stand as a
              cornerstone within our array of specialized industrial solutions.
              Backed by a skilled team proficient in the intricacies of truck
              handling, we offer a seamless experience in transporting and
              relocating a diverse range of goods and equipment. With a wealth
              of expertise, we pride ourselves on efficiently moving,
              delivering, and installing items, both new and used, across a
              spectrum of industries in the Lower Mainland.
              <br />
              <br />
              We provide a variety of crane services including: Hiab Cranes, Stiff Boom Cranes, Knuckle Boom Cranes, Mobile Cranes and more.
            </div>
            <div className="Buttons">
              <Link className="nav-link" to="/portfolio">
                <button className="ViewOurWorkButton">VIEW OUR WORK</button>
              </Link>
              <RequestQuoteButton />
            </div>
          </div>
        </div>
      </div>
      <div className="BoxContainer">
        <div
          className={`Box Box4 ${activeBox === 1 ? "active" : ""}`}
          onClick={() => handleBoxClick(1)}
        >
          <div className="BoxTitle">MILLWRIGHT SERVICES</div>
          <div className={`Triangle1 ${activeBox === 1 ? "active" : ""}`}></div>
        </div>
        <div
          className={`Dropdown DropdownBox4 ${activeBox === 1 ? "active" : ""}`}
        >
          <div className="LeftHalf">
            <img
              src="/images/ServicesImages/MillwrightServices.jpg"
              alt="Safe Moving"
              className="SMimg"
            />
          </div>
          <div className="RightHalf">
            <div className="Title">MILLWRIGHT SERVICES</div>
            <div className="Paragraph">
              <p>
                Alpha1 Industrial Services takes pride in offering top-notch
                millwright services that form the bedrock of our comprehensive
                industrial solutions. With a dedicated team of experienced
                professionals specializing in precision <b>machinery installation,
                maintenance, repair, electrical, and mechanical, therefore all services are 
                taken care of in house. </b> Our expertise spans a
                wide range of industries, and we are committed to ensuring the
                smooth operation of your equipment, enhancing efficiency, and
                minimizing downtime. Whether it's new installations or
                optimizing existing setups, you can trust Alpha1 Industrial
                Services to provide exceptional millwright services in the Lower
                Mainland and beyond.
              </p>
            </div>
            <div className="Buttons">
              <Link className="nav-link" to="/portfolio">
                <button className="ViewOurWorkButton">VIEW OUR WORK</button>
              </Link>
              <RequestQuoteButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesDropDowns;
