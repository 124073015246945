import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../ServicesPage/css/ServicesDropDowns.css";
import RequestQuoteButton from "../../RequestButton/js/RequestQuoteButton";

const IndustriesDropDowns1 = () => {
  const [activeBox, setActiveBox] = useState(null);

  const handleBoxClick = (boxIndex) => {
    setActiveBox(boxIndex === activeBox ? null : boxIndex);
  };

  return (
    <div className="ServicesDropDownsContainer">
      <div className="BoxContainer">
        <div
          className={`Box Box3 ${activeBox === 0 ? "active" : ""}`}
          onClick={() => handleBoxClick(0)}
          style={{
            backgroundImage: `url("/images/IndustriesImages/WareHouseBG.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="BoxTitle">WAREHOUSE</div>
          <div className={`Triangle1 ${activeBox === 0 ? "active" : ""}`}></div>
        </div>
        <div
          className={`Dropdown DropdownBox3 ${activeBox === 0 ? "active" : ""}`}
        >
          <div className="LeftHalf">
            <img
              src="/images/IndustriesImages/WareHouse.jpg"
              alt="Machinery Moving"
              className="MMimg"
            />
          </div>
          <div className="RightHalf">
            <div className="Title">WAREHOUSE</div>
            <div className="Paragraph">
              Warehouses, pivotal in supply chains, house products before their
              final journeys. Adapting to evolving industries, Alpha 1
              Industrial Services excels in orchestrating industrial equipment
              movement. From Surrey to Vancouver, they navigate the intricate
              landscape of machinery relocation. These spaces transition from
              storage to dynamic hubs, playing a crucial role in commerce.
              <br />
              <br />
              We offer Container Loading and Unloading, Trans Loading, Machine Storage and more.
            </div>
            <div className="Buttons">
              <Link className="nav-link" to="/portfolio">
                <button className="ViewOurWorkButton">VIEW OUR WORK</button>
              </Link>
              <RequestQuoteButton />
            </div>
          </div>
        </div>
      </div>
      <div className="BoxContainer">
        <div
          className={`Box Box4 ${activeBox === 1 ? "active" : ""}`}
          onClick={() => handleBoxClick(1)}
          style={{
            backgroundImage: `url("/images/IndustriesImages/ManufacturingShopsBG.png")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="BoxTitle">MANUFACTURING SHOPS</div>
          <div className={`Triangle1 ${activeBox === 1 ? "active" : ""}`}></div>
        </div>
        <div
          className={`Dropdown DropdownBox4 ${activeBox === 1 ? "active" : ""}`}
        >
          <div className="LeftHalf">
            <img
              src="/images/IndustriesImages/ManufacturingShops.jpeg"
              alt="Safe Moving"
              className="SMimg"
            />
          </div>
          <div className="RightHalf">
            <div className="Title">MANUFACTURING SHOPS</div>
            <div className="Paragraph">
              <p>
                In workshops, raw materials morph into intricate creations,
                bridging imagination and engineering. Alpha 1 Industrial
                Services exemplifies this realm, orchestrating industrial
                equipment relocation. These shops blend tradition and
                technology, crafting products of legacy and progress. Skilled
                artisans and machinery forge dedication. Manufacturing shops,
                where industry shapes, and Alpha 1 Industrial Services pioneers
                transformation.
              </p>
            </div>
            <div className="Buttons">
              <Link className="nav-link" to="/portfolio">
                <button className="ViewOurWorkButton">VIEW OUR WORK</button>
              </Link>
              <RequestQuoteButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustriesDropDowns1;
