import React from "react";
import Header from "../../Header/js/Header";
import Footer from "../../Footer/js/Footer";
import ContactGrid from "./ContactGrid";

function Contact() {
  return (
    <div className="Contact">
      <Header />
      <ContactGrid />
      <Footer />
    </div>
  );
}

export default Contact;
