import "../css/Hero.css"; // Import the CSS file
import RequestQuoteButton from "../../RequestButton/js/RequestQuoteButton";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

const Hero = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [videoError, setVideoError] = useState(false);

  useEffect(() => {
    const heroContainer = document.getElementById("heroContainer");
    heroContainer.style.height = `${window.innerHeight}px`; // set height in pixels based on window height
  }, []);

  const handleVideoError = () => {
    setVideoError(true);
  };

  return (
    <div className="heroContainer" id="heroContainer">
      <div className="ImageContainer">
        <div className="BlackOverlay"></div>
        {!videoError && (
          <video
            autoPlay
            loop
            muted
            className="HeroVideoIndex"
            onError={handleVideoError}
          >
            <source src="/videos/Cover.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        {videoError && (
          <img
            src="/images/HeroImageIndex.png"
            alt="HeroImageIndex"
            className="HeroImageIndex"
          />
        )}
      </div>
      <div className="LogoContainer">
        <motion.img
          src="/images/Logos/LargeLogo.png"
          alt="Logo"
          className="LogoImage"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.5, ease: "easeOut" }}
        />
        <div className="LogoButton">
          <RequestQuoteButton />
        </div>
      </div>
    </div>
  );
};

export default Hero;
