import React from "react";
import "../css/ServicesHero.css";

function ServicesHero() {
  return (
    <div className="ServicesHeroContainer">
      <div className="ServicesContainer">
        <h2 className="ServicesTitle">
          What <span style={{ color: "Red" }}>We</span> Offer
        </h2>
        <p className="ServicesText">
          Services
        </p>
      </div>
    </div>
  );
}

export default ServicesHero;
