import React from "react";
import Header from "../../Header/js/Header.js";
import Footer from "../../Footer/js/Footer.js";
import AUHero from "./AUHero.js";
import AUIntro from "./AUIntro.js";
import AUWritingBox1 from "./AUWritingBox1.js";
import AUCoreValues from "./AUCoreValues.js";
import AUWritingBox2 from "./AUWritingBox2.js";


function About() {
  return (
    <div className="About">
      <Header />
      <AUHero />
      <AUIntro />
      <AUWritingBox1 />
      <AUCoreValues />
      <AUWritingBox2 />
      <Footer />
    </div>
  );
}

export default About;
