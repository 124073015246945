import "../css/StickyRequestQuote.css"; // Import the CSS file
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const StickyRequestQuote = () => {
  useEffect(() => {
    const headerHeight = document.querySelector("header").offsetHeight;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const stickyCondition = scrollTop > window.innerHeight - headerHeight;

      //set RequestSticky display to block
      if (stickyCondition && window.innerWidth < 992) {
        document.querySelector(".sticky-quote-button").style.display = "block";
      } else {
        document.querySelector(".sticky-quote-button").style.display = "none";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 992) {
        document.querySelector(".sticky-quote-button").style.display = "none";
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Link className="nav-link" to="/contact">
      <button className="sticky-quote-button">REQUEST A QUOTE</button>
    </Link>
  );
};

export default StickyRequestQuote;
