import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from "./AboutPage/js/About";
import Portfolio from "./PortfolioPage/js/Portfolio";
import Services from "./ServicesPage/js/Services";
import Industries from "./IndustriesPage/js/Industries";
import Contact from "./ContactPage/js/Contact";
import HeaderHome from "./HomePage/js/HeaderHome";
import Hero from "./HomePage/js/Hero";
import WritingBox from "./HomePage/js/WritingBox";
import Accordion from "./HomePage/js/Accordion";
import IconBox from "./HomePage/js/IconBox";
import StickyRequestQuote from "./RequestButton/js/StickyRequestQuote";
import HomeAboutUs from "./HomePage/js/HomeAboutUs";
import FooterRequest from "./HomePage/js/FooterRequest";
import Footer from "./Footer/js/Footer";
import ScrollToTop from "./Animations/AnimationJS/ScrollToTop";

function App() {
  return (
    <Router>
      <div className="App">
      <ScrollToTop />
        <Switch>
          <Route path="/about" component={About} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/services" component={Services} />
          <Route path="/industries" component={Industries} />
          <Route path="/contact" component={Contact} />
          <Route exact path="/">
          <HeaderHome />
            <StickyRequestQuote />
            <Hero />
            <WritingBox />
            <Accordion />
            <IconBox />
            <HomeAboutUs />
            <FooterRequest />
            <Footer />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
