import React from "react";
import Footer from "../../Footer/js/Footer";
import Header from "../../Header/js/Header";
import PortfolioHero from "./PortfolioHero";
import GalleryReact from "./Gallery";

function Portfolio() {
  return (
    <div className="Portfolio">
      <Header />
      <PortfolioHero />
      <GalleryReact />
      <Footer />
    </div>
  );
}

export default Portfolio;
