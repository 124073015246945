import "../css/WritingBox.css"; // Import the CSS file

const WritingBox = () => {


  return (
    <div className="writingBoxContainer">
      <div className= "wrightingBoxContent">
        <h1 className="largeSentence">Experience the reliability of Alpha 1 Industrial Services</h1>

        <h6 className="smallSentence">
          where <b>timeliness</b>, <b>speed</b>, <b>transparent pricing</b> are
          our commitments from start to finish.
        </h6>
      </div>
    </div>
  );
};

export default WritingBox;
