import React from "react";
import "../css/IndustriesHero.css";

function IndustriesHero() {
  return (
    <div className="IndustriesHeroContainer">
      <div className="IndustriesContainer">
        <h2 className="IndustriesTitle">
          Industries
        </h2>
      </div>
    </div>
  );
}

export default IndustriesHero;
